import moment from "moment";

export type ContractChangeReason = "initialization" | "firstContract" | "newContract" | "contractManagementChange" | "extension" | "agreementChange";
export type AgreementStatusType = "INIT" | "OUT_FOR_PREFILL" | "OUT_FOR_MANUAL" | "OUT_FOR_SIGNATURE" | "CST_OUT_FOR_APPROVAL" | "CANCELLED" | "EXPIRED"  | "SIGNED" | "UNKNOWN";

export interface UserContract {
  contract: Contract,
  userId: string
}

export interface ActiveStatus {
  isActive: boolean;
  validThruDate?: moment.Moment;
  isSigned?: boolean;
}

interface ExtensionDataCount {
  data: string;
  extensionCount: number;
  validity?: string;
}

export class Contract {
  validFrom: string = "";
  validThru: string = "";
  type: "timeLimited" | "reasonLimited" = "timeLimited";
  limitationReason: string = "";
  limitationLength: string = "";
  firstJob: JobDetail = new JobDetail();
  monthlyWorkingHours: string = "";
  monthlyWorkingDays: string = "";
  extendedContracts: ExtendedContract[] = [];
  agreement: Agreement = new Agreement();
  overallStatus: AgreementStatusType = "OUT_FOR_PREFILL"; //used for faster contract-queries
  overallValidity: string[] = []; //used for faster contract-queries

  /**
   * Checks Contract and extendedContracts whether it is still active/valid.
   * @returns whether overall contract is active. If is Active the validThru date is also returned.
   */
  getActiveStatus(): ActiveStatus {
    const activeStatus: ActiveStatus = {
      isActive: false
    };
    if (!this.validThru && this.extendedContracts.length === 0) {
      return activeStatus;
    }
    const now = moment();
    const validThruDates = [];
    if (this.validThru) {
      validThruDates.push({ date: moment(this.validThru), status: this.agreement.getLastStatus().status });
    }
    for (const extendedContract of this.extendedContracts) {
      if (extendedContract.extensionReason === "validity" && extendedContract.validThru) {
        validThruDates.push({ date: moment(extendedContract.validThru), status: extendedContract.agreement.getLastStatus().status });
      }
    }
    console.log("getActiveStatus() - validThruDates",validThruDates)
    const activeValidThruDates = validThruDates.filter((validThruDate) => now.isBefore(validThruDate.date));
    if(activeValidThruDates.length > 0) {
      const lastActiveValidThruDate = activeValidThruDates[activeValidThruDates.length - 1];
      console.log("getActiveStatus() - lastActiveValidThruDate",lastActiveValidThruDate)
      activeStatus.isActive = true;
      activeStatus.validThruDate = lastActiveValidThruDate.date;
      activeStatus.isSigned = lastActiveValidThruDate.status == "SIGNED";
    }
    return activeStatus;
  }

  getLatestValidThruDate(): string {
    let latestValidThru = this.validThru;
    if (this.extendedContracts.length === 0) {
      return latestValidThru;
    }

    let latestValidThruDate = moment(latestValidThru);
    for (const extendedContract of this.extendedContracts) {
      const extValidThruDate = moment(extendedContract.validThru);
      if (extendedContract.extensionReason === "validity" && extendedContract.validThru && extValidThruDate.isAfter(latestValidThruDate)) {
        latestValidThru = extendedContract.validThru;
        latestValidThruDate = moment(latestValidThru);
      }
    }
    return latestValidThru;
  }

  getActiveMonths(aLastActiveMonth: moment.Moment | undefined = undefined): string[] {
    const fromDate = moment(this.validFrom);
    let toDate: moment.Moment | undefined = undefined;
    if(aLastActiveMonth) {
      toDate = aLastActiveMonth;
    } else {
      const activeStatus = this.getActiveStatus();
      if(!activeStatus.isActive || !activeStatus.validThruDate) {
        return [];
      }
      toDate = activeStatus.validThruDate
    }

    const months: string[] = [];
    while (fromDate <= toDate) {
      months.push(fromDate.format("MM/YYYY"));
      fromDate.add(1, 'month');
    }

    return months;
  }

  getAllMonths(): string[] {
    const fromDate = moment(this.validFrom);
    const toDate = moment(this.getLatestValidThruDate());

    const months: string[] = [];
    while (fromDate <= toDate) {
      months.push(fromDate.format("MM/YYYY"));
      fromDate.add(1, 'month');
    }

    return months;
  }

  getMonthlyWorkingHoursForMonth(aMonth: string): number | undefined {
    let workingHours: number | undefined = undefined;
    const monthDate = moment(aMonth,"MM/YYYY");
    if(this.getAllMonths().includes(aMonth)) {
      workingHours = Number(this.monthlyWorkingHours);
    }

    for (const extendedContract of this.extendedContracts) {
      if(extendedContract.extensionReason == "monthlyWorkingTime") {
        if(monthDate.isBetween(moment(extendedContract.validFrom),moment(extendedContract.validThru), null,'[]')) {
          workingHours = Number(extendedContract.monthlyWorkingHours);
        }
      }
    }
    return workingHours;
  }

  getMonthlyWorkingHoursArray(): ExtensionDataCount[] {
    const monthlyWorkingHours: ExtensionDataCount[] = [{
      data: this.monthlyWorkingHours,
      extensionCount: 0
    }]
    let extensionCount = 1;
    for (const extendedContract of this.extendedContracts) {
      if(extendedContract.extensionReason == "monthlyWorkingTime") {
        const validFrom = moment(extendedContract.validFrom);
        const validThru = moment(extendedContract.validThru);
        const isMoreThanOneMonth = validThru.diff(validFrom,"months",true) > 1.0
        let validityString = "";
        if(isMoreThanOneMonth) {
          validityString = validFrom.format("MM/YYYY") + " - " + validThru.format("MM/YYYY");
        } else {
          validityString = validFrom.format("MM/YYYY");
        }
        monthlyWorkingHours.push({data: extendedContract.monthlyWorkingHours, extensionCount: extensionCount, validity: validityString})
      }
      extensionCount++;
    }
    return monthlyWorkingHours;
  }
  getMonthlyWorkingDaysArray(): ExtensionDataCount[] {
    const monthlyWorkingDays: ExtensionDataCount[] = [{
      data: this.monthlyWorkingDays,
      extensionCount: 0
    }]
    let extensionCount = 1;
    for (const extendedContract of this.extendedContracts) {
      if(extendedContract.extensionReason == "monthlyWorkingTime") {
        const validFrom = moment(extendedContract.validFrom);
        const validThru = moment(extendedContract.validThru);
        const isMoreThanOneMonth = validThru.diff(validFrom,"months",true) > 1.0
        let validityString = "";
        if(isMoreThanOneMonth) {
          validityString = validFrom.format("MM/YYYY") + " - " + validThru.format("MM/YYYY");
        } else {
          validityString = validFrom.format("MM/YYYY");
        }
        monthlyWorkingDays.push({data: extendedContract.monthlyWorkingDays, extensionCount: extensionCount, validity: validityString})
      }
      extensionCount++;
    }
    return monthlyWorkingDays;
  }
  getValidThruArray(): ExtensionDataCount[] {
    const validThruDates: ExtensionDataCount[] = [{
      data: this.validThru,
      extensionCount: 0
    }]
    let extensionCount = 1;
    for (const extendedContract of this.extendedContracts) {
      if(extendedContract.extensionReason == "validity") {
        validThruDates.push({data: extendedContract.validThru, extensionCount: extensionCount})
      }
      extensionCount++;
    }
    return validThruDates;
  }

  getLastContract(): Contract | ExtendedContract {
    let lastContract: Contract | ExtendedContract = this;
    const lastExtendedContract = this.extendedContracts[this.extendedContracts.length-1];
    if(lastExtendedContract) {
      lastContract = lastExtendedContract;
    }
    return lastContract;
  }

  getLastAgreement(): Agreement {
    let lastAgreement = this.agreement;
    const lastExtendedContract = this.extendedContracts[this.extendedContracts.length-1];
    if(lastExtendedContract) {
      const lastAgreementOfExtendedContract = lastExtendedContract.agreement;
      if(lastAgreementOfExtendedContract) {
        lastAgreement = lastAgreementOfExtendedContract;
      }
    }
    return lastAgreement;
  }

  getLastAgreementStatus(): AgreementStatus {
    const lastAgreement = this.getLastAgreement();
    let lastAgreementStatus = lastAgreement.getLastStatus();
    return lastAgreementStatus;
  }

  validityExtensionCount(): number {
    // b) nicht mehr als 3 mal verlängert
    let validityExtensions = this.extendedContracts.filter((extendedContract) => {
      return extendedContract.extensionReason == "validity";
    });
    return validityExtensions.length;
  }

  isValidityPossible(aContract: ExtendedContract | Contract, aValidThruDate: string): boolean {
    // a) check max 1 Jahr länge
    // b) nicht mehr als 3 mal verlängert --> validityExtensionCount(), wird nur im Backend geprüft
    // c) Gesamtzeitraum von max. 2 jahren 
    let extensionPossible = false;
    const contractValidFromDate = moment(this.validFrom)
    const compareValidFromDate = moment(aContract.validFrom)
    const compareValidThruDate = moment(aValidThruDate)
    const extensionPeriod = compareValidThruDate.diff(compareValidFromDate, "years", true);
    const totalPeriod = compareValidThruDate.diff(contractValidFromDate, "years", true);
    if(extensionPeriod < 1.0 && totalPeriod < 2.0) {
      // Bedingungen zur Vertragsverlängerung erfüllt
      extensionPossible = true;
    }
    return extensionPossible;
  }

  getNextPendingAgreement(includeManual = false): Agreement | undefined {
    // Überprüfe das Agreement in der Contract-Klasse
    if(this.agreement.isAgreementPending(includeManual)) {
      return this.agreement;
    }

    // Iteriere über die extendedContracts-Array und prüfe jedes Agreement auf den Pending Status
    for (const extendedContract of this.extendedContracts) {
      if(extendedContract.agreement.isAgreementPending(includeManual)) {
        return extendedContract.agreement;
      }
    }

    // Wenn kein Agreement mit dem Status "OUT_FOR_SIGNATURE" gefunden wurde, gebe undefined zurück
    return undefined;
  }

  getContractNameByAgremeentId(agreementId: string): string {
    if(this.agreement.id == agreementId) {
      return "AV";
    } else {
      let extContractName = "ZV";
      let zvCounter = 1;
      for(const extContract of this.extendedContracts) {
        if(extContract.agreement.id == agreementId) {
          extContractName += zvCounter;
          break;
        }
        zvCounter++;
      }
      return extContractName;
    }
  }

  getAllAgreementIds(): string[] {
    const agreementIds = [];
    agreementIds.push(this.agreement.id);
    for(const extContract of this.extendedContracts) {
      agreementIds.push(extContract.agreement.id)
    }
    return agreementIds;
  }

  getSignedDate(): string {
    let date = "";
    if(this.agreement.getLastStatus().status == "SIGNED") {
      date = this.agreement.getLastStatus().time;
    }
    return date;
  }

  getReadableDateString(dateString: string) {
    if(!dateString) {
      return "-nd-";
    }
    if(dateString == "xxx") {
      return dateString;
    }
    if(dateString.split("T").pop()?.includes("00:00:00")) {
      return moment(dateString).format("DD.MM.YYYY")
    } else {
      return moment(dateString).format("DD.MM.YYYY - HH:mm")
    }
  }

  isAgreementStatusPending(agrStatus: AgreementStatus): boolean {
    if(agrStatus.status == "INIT" 
    || agrStatus.status == "OUT_FOR_PREFILL"
    || agrStatus.status == "OUT_FOR_SIGNATURE") {
      return true
    } else {
      return false;
    }
  }
  isAgreementStatusAccepted(agrStatus: AgreementStatus): boolean {
    if(agrStatus.status == "CST_OUT_FOR_APPROVAL") {
      return true
    } else {
      return false;
    }
  }
  isAgreementStatusConfirmed(agrStatus: AgreementStatus): boolean {
    if(agrStatus.status == "SIGNED") {
      return true
    } else {
      return false;
    }
  }
  isAgreementStatusDeclined(agrStatus: AgreementStatus): boolean {
    if(agrStatus.status == "CANCELLED" 
      || agrStatus.status == "EXPIRED") {
      return true
    } else {
      return false;
    }
  }

  getDataObject() {
    return {
      validFrom: this.validFrom,
      validThru: this.validThru,
      type: this.type,
      limitationReason: this.limitationReason,
      limitationLength: this.limitationLength,
      firstJob: this.firstJob.getDataObject(),
      monthlyWorkingHours: this.monthlyWorkingHours,
      monthlyWorkingDays: this.monthlyWorkingDays,
      extendedContracts: this.extendedContracts.map(extContract => extContract.getDataObject()),
      agreement: this.agreement.getDataObject(),
      overallStatus: this.overallStatus,
      overallValidity: this.getAllMonths()
      };
  }

  static initContractFromObject(o: any): Contract {
    let c = new Contract();
    if (o) {
      c.validFrom = o["validFrom"];
      c.validThru = o["validThru"];
      c.type = o["type"];
      c.limitationReason = o["limitationReason"] ? o["limitationReason"] : "";
      c.limitationLength = o["limitationLength"] ? o["limitationLength"] : "";
      c.firstJob = JobDetail.initJobDetailFromObject(o["firstJob"]);
      c.monthlyWorkingHours = o["monthlyWorkingHours"];
      c.monthlyWorkingDays = o["monthlyWorkingDays"];
      c.extendedContracts = ExtendedContract.initExtendedContractsFromObject(o["extendedContracts"]);
      c.agreement = Agreement.initAgreementFromObject(o["agreement"]);
      c.overallStatus = o["overallStatus"];
      c.overallValidity = o["overallValidity"];
    }
    return c;
  }
}

export class ExtendedContract {
  validFrom: string = "";
  validThru: string = "";
  monthlyWorkingHours: string = "";
  monthlyWorkingDays: string = "";
  extensionReason: "validity" | "monthlyWorkingTime" = "validity";
  agreement: Agreement = new Agreement();

  static initExtendedContractsFromObject(o: object[]): ExtendedContract[] {
    let extendedContracts: ExtendedContract[] = [];
    if (o) {
      for(const extendedContract of o) {
        const curExtContract = this.initExtendedContractFromObject(extendedContract);
        extendedContracts.push(curExtContract);
      }
    }
    return extendedContracts;
  }

  static initExtendedContractFromObject(o: any): ExtendedContract {
    let extContract = new ExtendedContract();
    if (o) {
      extContract.validFrom = o["validFrom"];
      extContract.validThru = o["validThru"];
      extContract.monthlyWorkingHours = o["monthlyWorkingHours"];
      extContract.monthlyWorkingDays = o["monthlyWorkingDays"];
      extContract.extensionReason = o["extensionReason"];
      extContract.agreement = Agreement.initAgreementFromObject(o["agreement"]);
    }
    return extContract;
  }

  getDataObject() {
    return {
      validFrom: this.validFrom,
      validThru: this.validThru,
      monthlyWorkingHours: this.monthlyWorkingHours,
      monthlyWorkingDays: this.monthlyWorkingDays,
      extensionReason: this.extensionReason,
      agreement: this.agreement.getDataObject()
    }
  }
}


export class Agreement {
  id: string = "";
  signingUrl: string = "";
  approvingUrl: string = "";
  viewingUrl: string = "";
  statusList: AgreementStatus[] = [new AgreementStatus()];

  pushStatusToAgreement(agrStatus: AgreementStatus) {
    let success = false;
    if(this.getLastStatus().status !== agrStatus.status) {
      this.statusList.push(agrStatus);
      success = true;
    }
    return success;
  }

  isAgreementPending(includeManual: boolean): boolean {
    let isPending = false;
    const lastStatus = this.getLastStatus();
    if(lastStatus) {
      if (lastStatus.status === "OUT_FOR_SIGNATURE" || lastStatus.status === "CST_OUT_FOR_APPROVAL" || (includeManual && lastStatus.status === "OUT_FOR_MANUAL")) {
        isPending = true;
      }
    }
    return isPending;
  }

  getLastStatus(): AgreementStatus {
    let lastAgreementStatus = this.statusList[this.statusList.length-1];
    return lastAgreementStatus;
  }

  static initAgreementFromObject(o: any): Agreement {
    let agreement = new Agreement();
    if (o) {
      agreement.id = o["id"];
      agreement.signingUrl = o["signingUrl"];
      agreement.approvingUrl = o["approvingUrl"];
      agreement.viewingUrl = o["viewingUrl"];
      agreement.statusList = Agreement.initStatusListFromObject(o["statusList"]);
    }
    return agreement;
  }

  static initStatusListFromObject(o: object[]): AgreementStatus[] {
    let agreementStatusList: AgreementStatus[] = [];
    if (o) {
      for(const agreementStatus of o) {
        const curAgreementStatus = AgreementStatus.initAgreementStatusFromObject(agreementStatus);
        agreementStatusList.push(curAgreementStatus);
      }
    }
    return agreementStatusList;
  }

  getDataObject() {
    return {
      id: this.id,
      signingUrl: this.signingUrl,
      approvingUrl: this.approvingUrl,
      viewingUrl: this.viewingUrl,
      statusList: this.statusList.map(agreementStatus => agreementStatus.getDataObject())
    }
  }
}

export class AgreementStatus {
  status: AgreementStatusType;
  time: string ;
  constructor(status: AgreementStatusType = "OUT_FOR_PREFILL", time: string = moment().format()) {
    this.status = status;
    this.time = time;
  }

  static isAgreementStatusType(value: string): value is AgreementStatusType {
    return (
      value === "INIT" ||
      value === "OUT_FOR_PREFILL" ||
      value === "OUT_FOR_SIGNATURE" ||
      value === "CST_OUT_FOR_APPROVAL" ||
      value === "CANCELLED" ||
      value === "EXPIRED" ||
      value === "SIGNED"
    );
  }

  static initAgreementStatusFromObject(o: any): AgreementStatus {
    let agreementStatus = new AgreementStatus();
    if (o) {
      agreementStatus.status = o["status"];
      agreementStatus.time = o["time"];
    }
    return agreementStatus;
  }

  getDataObject() {
    return {
      status: this.status,
      time: this.time,
    }
  }
}

export class JobDetail {
  id: string;
  name: string;
  posIndex: number;
  constructor(id: string = "", name: string = "", posIndex = -1) {
    this.id = id
    this.name = name
    this.posIndex = posIndex
  }

  static initJobDetailFromObject(o: any): JobDetail {
    let jobDetail = new JobDetail();
    if (o) {
      jobDetail.id = o["id"];
      jobDetail.name = o["name"];
      jobDetail.posIndex = typeof o["posIndex"] == "undefined" ? -1 : o["posIndex"];
    }
    return jobDetail;
  }

  getDataObject() {
    return {
      id: this.id,
      name: this.name,
      posIndex: this.posIndex
    }
  }
}

export class ContractChange {
  contract: Contract;
  reason: ContractChangeReason = "initialization";
  changedAt: string = moment().format();
  constructor(contract: Contract = new Contract(), reason: ContractChangeReason = "initialization") {
    this.contract = contract
    this.reason = reason
  }

  static initContractChangeFromObject(o: any): ContractChange {
    let contractChange = new ContractChange();
    if (o) {
      contractChange.contract = Contract.initContractFromObject(o["contract"]);
      contractChange.reason = o["reason"];
      contractChange.changedAt = o["changedAt"];
    }
    return contractChange;
  }

  getDataObject() {
    return {
      contract: this.contract.getDataObject(),
      reason: this.reason,
      changedAt: this.changedAt,
    }
  }
}