import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import {
  BrowserModule,
  BrowserTransferStateModule,
} from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { StatusBar } from "@ionic-native/status-bar/ngx";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { Clipboard } from "@ionic-native/clipboard/ngx";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TaxdetailsModule } from "./shared-modules/taxdetails.module";
import { SedcardModule } from "./shared-modules/sedcard/sedcard.module";

import { DatePicker } from "@ionic-native/date-picker/ngx";
import { HttpClientModule } from "@angular/common/http";

import { FullCalendarModule } from "@fullcalendar/angular"; // for FullCalendar!
import { CommonModule } from "@angular/common";
import { File } from "@ionic-native/file/ngx";

import { registerLocaleData } from "@angular/common";
import localeFrCa from "@angular/common/locales/de";
import localeFrCaExtra from "@angular/common/locales/extra/de";
import { Ng5SliderModule } from "ng5-slider";
import { IonicSelectableModule } from "ionic-selectable";
import { PreviewAnyFile } from "@ionic-native/preview-any-file/ngx";
import { FirebaseX } from "@ionic-native/firebase-x/ngx";


registerLocaleData(localeFrCa, localeFrCaExtra);

import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import timeGridPlugin from '@fullcalendar/timegrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // a plugin!

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin
]);

@NgModule({
    declarations: [AppComponent],
    imports: [
        IonicModule.forRoot(),
        BrowserTransferStateModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        ReactiveFormsModule,
        FormsModule,
        TaxdetailsModule,
        SedcardModule,
        HttpClientModule,
        FullCalendarModule,
        CommonModule,
        Ng5SliderModule,
        IonicSelectableModule,
    ],
    providers: [
        StatusBar,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Clipboard,
        DatePicker,
        File,
        PreviewAnyFile,
        FirebaseX
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
