import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AuthGuardService } from "./services/user/authguard.service";

const routes: Routes = [
  {
    path: "",
    redirectTo: "profiletabs",
    pathMatch: "full",
  },
  {
    path: "showprofile",
    loadChildren: () =>
      import("./pages/profile/showprofile/showprofile.module").then(
        (m) => m.ShowprofilePageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "showsedcard",
    loadChildren: () =>
      import("./pages/profile/showsedcard/showsedcard.module").then(
        (m) => m.ShowsedcardPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "showtaxdetails",
    loadChildren: () =>
      import("./pages/profile/showtaxdetails/showtaxdetails.module").then(
        (m) => m.ShowtaxdetailsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "marketoverview",
    loadChildren: () =>
      import("./pages/market/marketoverview/marketoverview.module").then(
        (m) => m.MarketoverviewPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "jobdetail",
    loadChildren: () =>
      import("./pages/market/jobdetail/jobdetail.module").then(
        (m) => m.JobdetailPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "positiondetail",
    loadChildren: () =>
      import("./pages/market/positiondetail/positiondetail.module").then(
        (m) => m.PositiondetailPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "jobsoverview",
    loadChildren: () =>
      import("./pages/dbjobs/jobsoverview/jobsoverview.module").then(
        (m) => m.JobsoverviewPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "addjob",
    loadChildren: () =>
      import("./pages/dbjobs/addjob/addjob.module").then(
        (m) => m.AddjobPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "showjob",
    loadChildren: () =>
      import("./pages/dbjobs/showjob/showjob.module").then(
        (m) => m.ShowjobPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "contractoverview",
    loadChildren: () =>
      import("./pages/users/contractmanagement/contractoverview.module").then(
        (m) => m.ContractoverviewPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'payrolls',
    loadChildren: () => import('./pages/users/payrolls/payrolls.module').then( m => m.PayrollsPageModule),
    canActivate: [AuthGuardService]
  },
  {
    path: "userdetail",
    loadChildren: () =>
      import("./pages/users/userdetail/userdetail.module").then(
        (m) => m.UserdetailPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/prelogin/login/login.module").then(
        (m) => m.LoginPageModule
      ),
  },
  {
    path: "signup",
    loadChildren: () =>
      import("./pages/prelogin/signup/signup.module").then(
        (m) => m.SignupPageModule
      ),
  },
  {
    path: "passwordreset",
    loadChildren: () =>
      import("./pages/prelogin/passwordreset/passwordreset.module").then(
        (m) => m.PasswordresetPageModule
      ),
  },
  {
    path: "profiletabs",
    loadChildren: () =>
      import("./pages/profile/profiletabs/profiletabs.module").then(
        (m) => m.ProfiletabsPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "useredit",
    loadChildren: () =>
      import("./pages/users/useredit/useredit.module").then(
        (m) => m.UsereditPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "phoneappointment",
    loadChildren: () =>
      import("./pages/phoneappointment/phoneappointment.module").then(
        (m) => m.PhoneappointmentPageModule
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: "scheduleappointment",
    loadChildren: () =>
      import(
        "./pages/phoneappointment/scheduleappointment/scheduleappointment.module"
      ).then((m) => m.ScheduleappointmentPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: "jobtabs",
    loadChildren: () =>
      import("./pages/dbjobs/jobtabs/jobtabs.module").then(
        (m) => m.JobtabsPageModule
      ),
  },
  {
    path: "dbjobsarchive",
    loadChildren: () =>
      import("./pages/dbjobs/dbjobsarchive/dbjobsarchive.module").then(
        (m) => m.DbjobsarchivePageModule
      ),
  },
  {
    path: "dbjobsfuture",
    loadChildren: () =>
      import("./pages/dbjobs/dbjobsfuture/dbjobsfuture.module").then(
        (m) => m.DbjobsfuturePageModule
      ),
  },
  {
    path: "userstabs",
    loadChildren: () =>
      import("./pages/users/userstabs/userstabs.module").then(
        (m) => m.UserstabsPageModule
      ),
  },
  {
    path: "useroverview",
    loadChildren: () =>
      import("./pages/users/usermanagement/useroverview.module").then(
        (m) => m.UseroverviewPageModule
      ),
  },
  {
    path: "timesheetedit",
    loadChildren: () =>
      import("./pages/timesheet/timesheetedit/timesheetedit.module").then(
        (m) => m.TimesheeteditPageModule
      ),
  },
  {
    path: "timesheetshow",
    loadChildren: () =>
      import("./pages/timesheet/timesheetshow/timesheetshow.module").then(
        (m) => m.TimesheetshowPageModule
      ),
  },
  {
    path: "chronictabs",
    loadChildren: () =>
      import("./pages/chronic/chronictabs/chronictabs.module").then(
        (m) => m.ChronictabsPageModule
      ),
  },
  {
    path: "chronicarchive",
    loadChildren: () =>
      import("./pages/chronic/chronicarchive/chronicarchive.module").then(
        (m) => m.ChronicarchivePageModule
      ),
  },
  {
    path: "chronicnow",
    loadChildren: () =>
      import("./pages/chronic/chronicnow/chronicnow.module").then(
        (m) => m.ChronicnowPageModule
      ),
  },
  {
    path: "chronicfuture",
    loadChildren: () =>
      import("./pages/chronic/chronicfuture/chronicfuture.module").then(
        (m) => m.ChronicfuturePageModule
      ),
  },
  {
    path: "chronicjobview",
    loadChildren: () =>
      import("./pages/chronic/chronicjobview/chronicjobview.module").then(
        (m) => m.ChronicjobviewPageModule
      ),
  },
  {
    path: "rateuser",
    loadChildren: () =>
      import("./pages/users/rateuser/rateuser.module").then(
        (m) => m.RateuserPageModule
      ),
  },
  {
    path: "sedcardwalkthrough",
    loadChildren: () =>
      import(
        "./pages/profile/sedcardwalkthrough/sedcardwalkthrough.module"
      ).then((m) => m.SedcardwalkthroughPageModule),
  },
  {
    path: "advancedsearch",
    loadChildren: () =>
      import("./pages/users/advancedsearch/advancedsearch.module").then(
        (m) => m.AdvancedsearchPageModule
      ),
  },
  {
    path: "partner",
    loadChildren: () =>
      import("./pages/dbpartner/partner/partner.module").then(
        (m) => m.PartnerPageModule
      ),
  },
  {
    path: "agb",
    loadChildren: () =>
      import("./pages/agb/agb.module").then((m) => m.AgbPageModule),
  },
  {
    path: "privacypolicy",
    loadChildren: () =>
      import("./pages/privacypolicy/privacypolicy.module").then(
        (m) => m.PrivacypolicyPageModule
      ),
  },
  {
    path: "consent",
    loadChildren: () =>
      import("./pages/consent/consent.module").then((m) => m.ConsentPageModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'userexport',
    loadChildren: () => import('./pages/users/userexport/userexport.module').then( m => m.UserexportPageModule)
  },
  {
    path: 'document-viewer',
    loadChildren: () => import('./pages/other/document-viewer/document-viewer.module').then( m => m.DocumentViewerPageModule)
  },
  {
    path: 'actual-timesheets',
    loadChildren: () => import('./pages/dbjobs/actual-timesheets/actual-timesheets.module').then( m => m.ActualTimesheetsPageModule)
  },
  { path: "**", redirectTo: "profiletabs" },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      useHash: true,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
